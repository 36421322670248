import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/hepato-kak-da-namalim-riska-header.jpg"
import LuteinComplexTablet from "./../../images/hepato-kak-da-namalim-riska-header.jpg"
import LuteinComplexDesktop from "./../../images/hepato-kak-da-namalim-riska-header.jpg"
import LuteinComplexXL from "./../../images/hepato-kak-da-namalim-riska-header.jpg"

const HepatoRisk = () => (
  <Layout backButton={true} pageInfo={{ pageName: "hepato-risk" }}>
    <Seo title="Как да намалим риска от чернодробни заболявания?" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>
              КАК ДА НАМАЛИМ РИСКА ОТ ЧЕРНОДРОБНИ ЗАБОЛЯВАНИЯ?
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Как да намалим риска от чернодробни заболявания?"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Как да намалим риска от чернодробни заболявания?
          </h1>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={12}>
          <p>
            Чернодробните заболявания се „отключват” от много и различни причини
           - болести и инфекции, които влияят върху клетките, тъканите или
            функциите на черния дроб, злоупотреба с алхохол, прием на лекарства
            и др.
          </p>
          <p>
            Специалистите, с цел предотвратяването на чернодробни проблеми,
            съветват:
          </p>
          <ol>
            <li>
              Избягвайте лекарства в големи количества. Приемането на лекарства
              като болкоуспокояващи например или всякакви други медикаменти в
              големи количества, могат да причинят износване на тъканта и
              клетките на черния дроб. Когато ви дадат рецепта или купувате
              лекарства, за които не се иска такава, препоръчва се да взимате
              медикаментите, които ви е казал вашия лекар. Също така, ако имате
              въпроси или смятате, че сегашната ви доза на предписаните
              лекарства трябва да бъде променена, първо говорете с лекаря си.
            </li>
            <li>
              Не пийте алкохол, или го намалете до минимум. Алкохолизма с
              течение на времето би могъл да навреди на чернодробните клетки,
              поради трудния процес на разграждане на алкохола до използваемо
              вещество. Този процес може да доведе до алкохолно причинени
              чернодробни болести, които отговарят за 24% от всички чернодробни
              заболявания.
            </li>
            <li>
              Помислете добре с какво и как се храните? Не пре­яж­дай­те,
              по-доб­ре яж­те 4-5 пъ­ти на ден по мал­ко, от­кол­ко­то вед­нъж,
              но мно­го. Не се ув­ли­чай­те по маз­ни хра­ни, жи­во­тин­с­ки­те
              маз­ни­ни за­ме­не­те с рас­ти­тел­ни. Редовното преяждане,
              храните от типа фаст фууд, сладкарските и мазни продукти,
              значително повишават риска от цироза, което обикновено засяга
              привържениците на спиртните напитки. Доказано е, че прекалената
              употреба на мазнини постепенно убива Т-клетките в черния дроб,
              което води до възпалителни процеси.
            </li>
            <li>
              Пазете се и поддържайте добра хигиена. Добрата хигиена, безопасния
              секс, избягването на наркотици и ваксинирането срещу хепатит са
              все начини да се намали риска от чернодробни заболявания. Няма
              лечение на хепатити Б и С, но те могът да бъдат предотвратени с
              ваксина. 51% от всички чернодробни заболявания са хепатитни.
            </li>
            <li>
              Препоръчително е приемането на хранителни добавки повлияващи
              възстановителните процеси на черния дроб, както и регенеративните
              функции на чернодробните клетки.
            </li>
          </ol>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            "ХЕПАТОФЕЛИН" е натурален продукт, съдържащ оптимална доза
            Силимарин, за който има научно доказан ефект върху чернодробното
            здраве. "ХЕПАТОФЕЛИН" се препоръчва за профилактика и лечение на
            хронични хепатити и чернодробни възпаления, цироза и алкохолна
            интоксикация. Има доказателства, че Силимарина подпомага
            чернодробните клетки в борбата им с различни токсини и подобряват
            регенерацията им. "ХЕПАТОФЕЛИН" е произведен от Capsugel - Франция
            по технологията Licaps®, която гарантира пълно и лесно усвояване от
            организма на активните съставки.
          </p>
        </Col>
      </Row>

      <Row className="product-container">
        <Col>
          <p>Можете да откриете ХЕПАТОФЕЛИН в най-близката до вас аптека.</p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:029446006">02 944 60 06</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h3>
            <Link to="/hepatofelin">
              Повече информация за ХЕПАТОФЕЛИН можете да намерите тук.
            </Link>
          </h3>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <p>
            *Да не се превишава препоръчваната дневна доза! Продуктът да не се
            използва като заместител на разнообразното хранене!
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default HepatoRisk
